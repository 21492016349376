<template>
  <div>
    <v-row class="mx-0"><!-- mb-6  -->
      <v-flex  class="text-left xs1" style="padding: 1.4vh 0 !important;">
        <v-flex v-if="$route.path !== '/dashboard'">
          <v-btn fab icon :color="$route.path !== '/dashboard' ? 'rgba(0, 0, 0, 0.54)' : 'transparent'" @click="goBack" small>
            <v-icon>{{ 'mdi-arrow-left-bold' }}</v-icon>
          </v-btn>
        </v-flex>
      </v-flex>
      <v-flex class="mt-1 xs10 mainboxdesignfix" :class="timerData.isTimeRunning && $route.path !== '/stemp'  ? $route.path === '/dashboard' ? '' : 'xs10' :  $route.path === '/dashboard' ? '' : 'xs10'">
        <v-flex class="boxoflogo">
          <v-layout justify-center>
            <v-img height="40" contain :src="require(`../assets/${logoUrl}`)" @click="navigateToDashboard"></v-img>
          </v-layout>
          <!-- :href="`https://${getAppHost}`" target="_blank" --><a style="text-decoration:none" @click="navigateToDashboard"><p class="text-center mt-0 orange--text mb-0 font-weight-bold text-body-2">{{getAppHost}}</p></a>
        </v-flex>
        <v-flex class="text-right timerdesign" :class="timerData.isTimeRunning && $route.path !== '/stemp' ? 'pr-1' : ''">
          <timer v-if="isAuthenticated && $route.path !== '/stemp'"></timer>
        </v-flex>
      </v-flex>
      <v-flex xs1 class="my-auto fix__icon__design"><!-- checkFeatureEnabled('tasks') -->
        <v-icon large class="mr-7" @click="refreshContentUpdate()">mdi-refresh-circle</v-icon>
        <template v-if="checkFeatureEnabled('tasks')">
          <router-link to="/tasks">
            <v-badge left :content="tasksCount" overlap>
              <v-icon>mdi-calendar-check</v-icon>
            </v-badge>
          </router-link>
        </template>
      </v-flex>
    </v-row>
    <v-divider class="mx-1 grey darken-1"></v-divider>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FeaturesMixin from '@/mixins/features_list'
export default {
  mixins: [FeaturesMixin],
  data () {
    return {
      logoUrl: process.env.VUE_APP_LOGO_FILE_NAME,
      tasksCount: 'test'
    }
  },
  components: {
    timer: () => import('../components/NavbarTimer')
  },
  created () {
    this.tasksCount = window.localStorage.getItem(process.env.VUE_APP_TASKS_COUNT) || '0'
    // this.tasksCount = this.$cookie.get(process.env.VUE_APP_TASKS_COUNT) || '0'
  },
  methods: {
    navigateToDashboard () {
      if (this.$route.path !== '/dashboard') this.$router.push('/dashboard')
    },
    goBack () {
      const path = this.$route.path
      if (['/equipments', '/buildings', '/timer'].includes(path)) this.$router.push('/dashboard')
      else this.$router.go(-1) // this.$eventBus.$emit('goToPreviousPage')
    },
    refreshContentUpdate () {
      if (this.getNewContentAvailable) {
        this.$store.dispatch('reloadNewContent')
      } else {
        this.$router.go()
      }
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'timerData', 'getNewContentAvailable']),
    getAppHost () {
      return this.$cookie.get('WelditPwa_host-ref')
    }
  }
}
</script>
<style scoped>
.mainboxdesignfix{
  overflow: hidden;
  position: relative;
}
.timerdesign{
  position: absolute;
  top: -5px;
  right: 0;
}
@media only screen and (max-width:1024px){
  .fix__icon__design{
    display: flex;
    align-items: center;
    position: relative;
    right: 35px;
  }
  .timerdesign{
    right: 35px;
  }
}
@media only screen and (max-width: 575px){
  .fix__icon__design{
    display: block;
    right: 0;
  }
  .timerdesign{
    position: unset;
    width: 132px !important
  }
  .mainboxdesignfix{
    display: flex;
    align-items: center;
  }
  .boxoflogo > div > div .v-responsive__content{
    background-color: red;
  }
}
</style>
