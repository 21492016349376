const icons = [
  'mdi-account',
  'mdi-access-point',
  'mdi-account-card-details',
  'mdi-animation',
  'mdi-file-document',
  'mdi-chart-line',
  'mdi-google-pages',
  'mdi-google-earth',
  'mdi-google-circles-extended',
  'mdi-calendar',
  'mdi-calendar-clock',
  'mdi-gauge',
  'mdi-apple-safari',
  'mdi-assistant',
  'mdi-camera-timer',
  'mdi-collage',
  'mdi-clipboard-check',
  'mdi-clipboard-text'
]
export default icons
