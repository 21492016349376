const field = [{
  type: 1,
  name: 'Text field',
  icon: 'mdi-textbox'
}, {
  type: 2,
  name: 'Text area',
  icon: 'mdi-format-align-left'
}, {
  type: 3,
  name: 'Checkbox',
  icon: 'mdi-checkbox-marked-outline'
}, {
  type: 4,
  name: 'Radio',
  icon: 'mdi-radiobox-marked'
}, {
  type: 5,
  name: 'Select',
  icon: 'mdi-playlist-check'
}, {
  type: 6,
  name: 'Date',
  icon: 'mdi-calendar-today'
}, {
  type: 7,
  name: 'Time',
  icon: 'mdi-calendar-clock'
}, {
  type: 9,
  name: 'Divider',
  icon: 'mdi-minus'
}, {
  type: 10,
  name: 'Signature',
  icon: 'mdi-message-draw'
}, {
  type: 11,
  name: 'New Panel',
  icon: 'mdi-window-maximize'
}, {
  type: 12,
  name: 'Email',
  icon: 'mdi-email'
}, {
  type: 13,
  name: 'Number',
  icon: 'format_list_numbered'
}, {
  type: 14,
  name: 'Phone',
  icon: 'mdi-phone'
}, {
  type: 15,
  name: 'User',
  icon: 'mdi-account'
}]
// {
//   type: 8,
//   name: 'File',
//   icon: 'mdi-attachment mdi-rotate-315'
// },
export default field
